import React, { useRef } from "react";
import { MessageContext } from "./context";
import { socket } from "../../socket";

const MessageContextProvider = ({ children }) => {
	const [messages, setMessages] = React.useState([]);
	const [listScrolledToBottom, setListScrolledToBottom] = React.useState(false);
	const virtuoso = useRef(null);
	const [messagesIndicator, setMessageIndicator] = React.useState(false);
	const listScrollRef = React.useRef(null);

	React.useEffect(() => {
		listScrollRef.current = listScrolledToBottom;
	}, [listScrolledToBottom]);

	React.useEffect(() => {
		if (!!messagesIndicator && !!listScrolledToBottom) {
			setMessageIndicator(false);
		}
	}, [messagesIndicator, listScrolledToBottom]);

	const scrollToBottom = React.useCallback(() => {
		virtuoso.current?.scrollToIndex({
			index: messages.length,
			align: "start",
			behavior: "smooth",
		});
	}, [messages.length]);

	const watchMessages = (msg) => {
		let index = 0;
		setMessages((prevMessages) => {
			const updatedMessages = [...prevMessages, msg];
			index = updatedMessages.length;
			// window.scrollTo(0, document.body.scrollHeight);
			// console.log(virtuoso.current);
			// virtuoso.current.autoscrollToBottom();

			return updatedMessages;
		});
		if (listScrollRef.current) {
			setTimeout(() => {
				virtuoso.current?.scrollToIndex({
					index: index,
					align: "start",
				});
			});
		} else {
			setMessageIndicator(true);
		}
	};

	const onRemoveMessage = (data) => {
		setMessages((prevMessages) => {
			let items = [...prevMessages];
			const index = items.findIndex((itm) => itm._id === data);
			items[index] = { ...items[index], removed: true };
			return items;
		});
	};

	React.useEffect(() => {
		socket.on("chat message", watchMessages);
		socket.on("delete message", onRemoveMessage);

		return () => {
			socket.off("chat message", watchMessages);
			socket.off("delete message", onRemoveMessage);
		};
	}, []);

	const values = React.useMemo(
		() => ({ messages, setMessages, virtuoso, setListScrolledToBottom, messagesIndicator, scrollToBottom }),
		[messages, setMessages, virtuoso, setListScrolledToBottom, messagesIndicator, scrollToBottom],
	);

	return <MessageContext.Provider value={values}>{children}</MessageContext.Provider>;
};

export default MessageContextProvider;
