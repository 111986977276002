import React from "react";
import "./App.css";
import Chat from "./pages/chat";
import AuthContextProvider from "./context/auth";
import RoomContextProvider from "./context/room";
import MessageContextProvider from "./context/message";

function App() {
	return (
		<AuthContextProvider>
			<MessageContextProvider>
				<RoomContextProvider>
					<div className="App">
						{/*<header className="App-header"></header>*/}
						<Chat />
					</div>
				</RoomContextProvider>
			</MessageContextProvider>
		</AuthContextProvider>
	);
}

export default App;
