export const style = {
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
		gap: 20,
		padding: "0 40px",
	},
	itemContainer: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		maxWidth: 300,
	},
	inputContainer: {
		border: "1px solid #D1D1D8",
		borderRadius: 10,
		width: "100%",
		backgroundColor: "#FCFDFD",
		padding: "5px 5px",
		height: 40,

		position: "relative",
	},
	inputHelperText: {
		position: "absolute",
		top: -10,
		left: 10,
		backgroundColor: "white",
		padding: "2px 5px",
		fontFamily: "Branding SemiBold",
		color: "rgba(24,43,67,0.5)",
		fontSize: 13,
		transition: "opacity 0.3s ease",
	},
	input: {
		flexGrow: 1,
		border: "none",
		borderRadius: 10,
		height: "100%",
		backgroundColor: "transparent",
		fontFamily: "Branding SemiBold",
		color: "rgba(24,43,67,0.9)",
		fontSize: 14,
		width: "100%",
	},
	button: {
		backgroundColor: "#162A41",
		border: "none",
		padding: "1rem 2rem",
		margin: "0 0.5rem",
		borderRadius: 10,
		outline: "none",
		color: "#fff",
		fontFamily: "Branding SemiBold",
		marginLeft: 20,
		cursor: "pointer",
	},
	error: {
		fontFamily: "Branding Medium",
		color: "red",
		fontSize: 13,
		textAlign: "left",
		alignSelf: "flex-start",
		marginTop: 5,
	},
};
