import React from "react";
import { style } from "./userForm.style";
import useAuth from "../../context/auth/useAuth";
import { ReactComponent as Logo } from "../../resources/images/svg/logo.svg";

const UserForm = () => {
	const [firstName, setFirstName] = React.useState("");
	const [lastName, setLastName] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [errors, setErrors] = React.useState({ firstName: false, lastName: false, email: false });
	const { setUserInfo } = useAuth();

	const updateUser = () => {
		let err = { firstName: false, lastName: false, email: false };
		if (firstName.trim().length < 2) {
			err.firstName = true;
		}
		if (lastName.trim().length < 2) {
			err.lastName = true;
		}
		if (!/^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm.test(email)) {
			err.email = true;
		}

		if (!!err.firstName || !!err.lastName || !!err.email) {
			setErrors(err);
			return;
		}

		if (!!firstName && !!lastName && !!email) {
			setUserInfo({ firstName, lastName, email });
			localStorage.setItem("user", JSON.stringify({ firstName, lastName, email }));
		}
	};

	return (
		<div style={style.container}>
			<Logo />
			<div style={style.itemContainer}>
				<div style={style.inputContainer}>
					<span style={{ ...style.inputHelperText, opacity: +(firstName.length > 0) }}>Prénom</span>
					<input
						style={style.input}
						id="input"
						autoComplete="off"
						value={firstName}
						placeholder={"Prénom"}
						onChange={(event) => setFirstName(event.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								updateUser();
							}
						}}
					/>
				</div>
				{errors.firstName && <span style={style.error}>Le prénom doit contenir au moins 2 caractères</span>}
			</div>
			<div style={style.itemContainer}>
				<div style={style.inputContainer}>
					<span style={{ ...style.inputHelperText, opacity: +(lastName.length > 0) }}>Nom</span>
					<input
						style={style.input}
						id="input"
						autoComplete="off"
						placeholder={"Nom"}
						value={lastName}
						onChange={(event) => setLastName(event.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								updateUser();
							}
						}}
					/>
				</div>
				{errors.lastName && <span style={style.error}>Le nom doit contenir au moins 2 caractères</span>}
			</div>
			<div style={style.itemContainer}>
				<div style={style.inputContainer}>
					<span style={{ ...style.inputHelperText, opacity: +(email.length > 0) }}>Email</span>
					<input
						style={style.input}
						id="input"
						autoComplete="off"
						placeholder={"Email"}
						value={email}
						onChange={(event) => setEmail(event.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								updateUser();
							}
						}}
					/>
				</div>
				{errors.email && <span style={style.error}>Email invalide</span>}
			</div>
			<button style={style.button} onClick={updateUser}>
				Rejoindre le tchat
			</button>
		</div>
	);
};

export default UserForm;
