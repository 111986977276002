import React from "react";
import { RoomContext } from "./context";
import axios from "axios";
import useMessage from "../message/useMessage";

const RoomContextProvider = ({ children }) => {
	const [userInfo, setUserInfo] = React.useState(null);
	const [token, setToken] = React.useState(null);
	const [roomId, setRoomId] = React.useState(null);
	const [hasLoadedRoom, setHasLoadedRoom] = React.useState(false);

	const { setMessages, virtuoso } = useMessage();

	const joinRoom = React.useCallback((socket) => {
		const queryString = window.location?.pathname?.replace("/", "");
		if (!socket) return;
		axios
			.post(`${process.env.REACT_APP_API_URL}/room/join`, { socketId: socket.id, roomId: queryString })
			.then((response) => {
				setHasLoadedRoom(true);
				if (response.data) {
					setRoomId(response.data?.roomId);
					if (response.data?.room?.messages) {
						setMessages([...response.data?.room?.messages, { isWelcomeMessage: true }]);
						setTimeout(() => {
							virtuoso.current?.scrollToIndex({
								index: response.data?.room?.messages.length,
								align: "start",
								behavior: "smooth",
							});
						}, 200);
					}
				}
			})
			.catch(() => {
				setHasLoadedRoom(true);
			});
	}, []);

	const values = React.useMemo(
		() => ({ token, setUserInfo, joinRoom, roomId, hasLoadedRoom }),
		[token, setUserInfo, joinRoom, roomId, hasLoadedRoom],
	);

	return <RoomContext.Provider value={values}>{children}</RoomContext.Provider>;
};

export default RoomContextProvider;
