import React, { useRef } from "react";
import { socket } from "../socket";
import axios from "axios";
import useAuth from "../context/auth/useAuth";
import UserForm from "../components/user/userForm";
import useRoom from "../context/room/useRoom";
import { Comment } from "react-loader-spinner";
import { Virtuoso } from "react-virtuoso";
import useMessage from "../context/message/useMessage";
import { chatStyle } from "./chat.style";
import { BsFillTrash3Fill, BsFillShieldLockFill, BsEmojiSmileFill } from "react-icons/bs";
import EmojiPicker from "emoji-picker-react";
import { ReactComponent as Arrow } from "../resources/images/svg/arrow-left.svg";
import useOutsideClick from "../hooks/useOutsideClick";
import dayjs from "dayjs";
import useWindowDimensions from "../hooks/useWindowDimensions";

function Chat() {
	const [isConnected, setIsConnected] = React.useState(socket.connected);
	const [emojiPickerOpen, setEmojiPickerOpen] = React.useState(false);
	const [message, setMessage] = React.useState("");
	const {
		isAuthenticated,
		userInfo,
		token,
		idInexplore,
		temporaryId,
		hasModerationAccess,
		isModerator,
		isSuperAdmin,
		superAdminToken,
		isClosed
	} = useAuth();
	const { joinRoom, roomId, hasLoadedRoom } = useRoom();
	const { messages, virtuoso, setListScrolledToBottom, messagesIndicator, scrollToBottom } = useMessage();
	const inputRef = React.useRef(null);
	const emojiRef = React.useRef(null);
	const { width } = useWindowDimensions();

	useOutsideClick(emojiRef, (event) => {
		if (emojiPickerOpen) {
			if (event.target.parentNode.className.baseVal !== "emoji-picker-btn") {
				setEmojiPickerOpen(false);
			}
		}
	});

	React.useEffect(() => {
		function onConnect(sock) {
			joinRoom(socket);
			setIsConnected(true);
		}

		function onDisconnect() {
			setIsConnected(false);
		}

		socket.on("connect", onConnect);
		socket.on("disconnect", onDisconnect);

		// socket.on("chat message", watchMessages);

		return () => {
			socket.off("connect", onConnect);
			socket.off("disconnect", onDisconnect);
			// socket.off("chat message", watchMessages);
		};
	}, []);

	const sendMessage = () => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/message/create`, {
				message,
				roomId,
				...(userInfo || null),
				authToken: token,
				idInexplore,
				temporaryId,
				asModerator: isModerator,
				superAdminToken,
			})
			.then((response) => console.log(response));
		setMessage("");
	};

	const removeMessage = (messageId) => {
		axios
			.post(`${process.env.REACT_APP_API_URL}/message/delete`, {
				messageId,
				roomId,
				idInexplore,
				temporaryId,
				asModerator: isModerator,
				superAdminToken,
			})
			.then((response) => console.log(response))
			.catch((e) => console.warn(e));
	};

	const openHasModerator = () => {
		console.log();
		window.open(isClosed ? `${window.location.href}&moderator=true`:`${window.location.href}?moderator=true`, "_blank");
	};

	if (!hasLoadedRoom) return null;
	// <Comment
	// 	visible={true}
	// 	height="80"
	// 	width="80"
	// 	ariaLabel="comment-loading"
	// 	wrapperStyle={{}}
	// 	wrapperClass="comment-wrapper"
	// 	color="#fff"
	// 	backgroundColor="#F4442E"
	// />

	if (!isAuthenticated) return <UserForm />;

	return (
		<div style={chatStyle.container}>
			<div style={chatStyle.header}>
				{(!!isModerator || !!hasModerationAccess || !!isSuperAdmin) && (
					<div style={chatStyle.bannerModerator}>
						{!!isModerator && <span>Connecté en tant que modérateur</span>}
						{!!isSuperAdmin && <span>Connecté en tant que administrateur</span>}
						{hasModerationAccess && !isModerator && (
							<div
								onClick={openHasModerator}
								style={{ cursor: "pointer", display: "flex", alignItems: "center", gap: 20 }}
							>
								<span>Connectez vous en tant que modérateur</span>
								<BsFillShieldLockFill />
							</div>
						)}
					</div>
				)}

				{/*<div>room: {roomId}</div>*/}
				{/*{hasModerationAccess && !isModerator && (*/}
				{/*	<div onClick={openHasModerator} style={{ cursor: "pointer" }}>*/}
				{/*		<BsFillShieldLockFill />*/}
				{/*	</div>*/}
				{/*)}*/}
			</div>
			{messagesIndicator && (
				<div onClick={scrollToBottom} style={chatStyle.newMessagesContainer}>
					<Arrow style={{ transform: "rotate(-90deg)", marginRight: 10 }} width={20} />
					<span>Nouveaux messages</span>
				</div>
			)}
			<Virtuoso
				style={{ flex: 1 }}
				useWindowScroll={false}
				data={messages}
				alignToBottom={true}
				initialTopMostItemIndex={messages.length}
				ref={virtuoso}
				atBottomStateChange={setListScrolledToBottom}
				itemContent={(_, msg) => (
					<MessageItem
						msg={msg}
						removeMessage={removeMessage}
						isModerator={isModerator}
						idInexplore={idInexplore}
						temporaryId={temporaryId}
						isSuperAdmin={isSuperAdmin}
					/>
				)}
			/>

			{ !isClosed && (<div id="form" style={chatStyle.form}>
				<div style={chatStyle.inputContainer}>
					<input
						style={chatStyle.input}
						ref={inputRef}
						id="input"
						autoComplete="off"
						value={message}
						placeholder={isModerator ? "Écrire un message en tant que modérateur" : "Écrire un message"}
						onChange={(event) => setMessage(event.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								sendMessage();
							}
						}}
					/>
					<div style={chatStyle.emojiContainer}>
						<BsEmojiSmileFill
							className={"emoji-picker-btn"}
							color={"#162A41"}
							size={20}
							onClick={() => setEmojiPickerOpen((prev) => !prev)}
						/>
						<div ref={emojiRef} style={{ position: "absolute", bottom: 50, right: width > 500 ? 0 : -120 }}>
							<EmojiPicker
								width={Math.min(350, width - 20)}
								searchPlaceholder={"Rechercher"}
								open={emojiPickerOpen}
								onEmojiClick={(emoji) => {
									const indexPosition = inputRef.current.selectionEnd;
									const newString =
										message.slice(0, indexPosition) + emoji.emoji + message.slice(indexPosition);
									setMessage(newString);
									setEmojiPickerOpen(false);
								}}
							/>
						</div>
					</div>
				</div>
				<div style={{}}></div>
				<button style={chatStyle.buttonSend} onClick={sendMessage}>
					Envoyer
				</button>
			</div>)}
		</div>
	);
}

export default Chat;

const MessageItem = ({ msg, removeMessage, isModerator, idInexplore, temporaryId, isSuperAdmin }) => {
	const [isHovering, setIsHovering] = React.useState(false);

	const canRemoveMessage = React.useMemo(() => {
		return isModerator || isSuperAdmin;
		// if (isModerator) return true;
		// return msg.temporaryId === temporaryId || msg.idInexplore === idInexplore;
	}, [idInexplore, isModerator, msg.idInexplore, msg.temporaryId, temporaryId, isSuperAdmin]);

	if (msg?.isWelcomeMessage) {
		return (
			<div style={chatStyle.messages.welcomeContainer}>
				<span style={chatStyle.messages.welcome}>Bienvenue sur le live Inexploré</span>
			</div>
		);
	}

	return (
		<div
			style={{
				...chatStyle.messages.messageContainer,
				backgroundColor: isHovering ? "rgba(22,42,65,0.21)" : "transparent",
			}}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<p style={{ margin: "8px 0" }}>
				{msg?.removed ? (
					<span style={chatStyle.messages.messageDeleted}>Message supprimé</span>
				) : (
					<>
						<span style={chatStyle.messages.name}>
							{msg?.asModerator || msg?.asSuperAdmin
								? "L'équipe Inexploré"
								: `${msg.firstName} ${msg.lastName[0]}`}
						</span>
						<span style={chatStyle.messages.messageContent}>: {msg?.message}</span>
					</>
				)}
			</p>
			<div style={{ ...chatStyle.messages.rightContainer, opacity: +isHovering }}>
				<div style={chatStyle.messages.time}>
					<span>{dayjs(msg?.createdAt).format("HH:mm")}</span>
				</div>
				{!!canRemoveMessage && (
					<div
						style={{
							opacity: +isHovering,
							...chatStyle.messages.trashContainer,
						}}
						onClick={() => removeMessage(msg._id)}
					>
						<BsFillTrash3Fill color={"#fff"} size={15} />
					</div>
				)}
			</div>
		</div>
	);
};
