export const chatStyle = {
	container: {
		display: "flex",
		flex: 1,
		height: "100%",
		flexDirection: "column",
	},
	header: {
		display: "flex",
		gap: 20,
		alignItems: "center",
		justifyContent: "center",
	},

	form: {
		background: "#162A410B",
		padding: "10px",
		bottom: 0,
		left: 0,
		right: 0,
		display: "flex",
		height: "4rem",
		boxSizing: "border-box",
		backdropFilter: "blur(10px)",
	},
	inputContainer: {
		flexGrow: 1,
		border: "1px solid #D1D1D8",
		borderRadius: 10,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#FCFDFD",
		padding: "5px 5px",
	},
	input: {
		flexGrow: 1,
		border: "none",
		borderRadius: 10,
		height: "100%",
		backgroundColor: "#FCFDFD",
		fontFamily: "Branding SemiBold",
		color: "rgba(24,43,67,0.9)",
		fontSize: 14,
	},
	emojiContainer: {
		margin: "0 10px",
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
	},
	buttonSend: {
		backgroundColor: "#162A41",
		border: "none",
		padding: "0 1rem",
		margin: "0 0.5rem",
		borderRadius: 10,
		outline: "none",
		color: "#fff",
		fontFamily: "Branding SemiBold",
		marginLeft: 20,
		cursor: "pointer",
	},
	newMessagesContainer: {
		padding: "0 20px",
		height: 40,
		backgroundColor: "#162a41",
		position: "absolute",
		bottom: 80,
		left: "50%",
		transform: "translateX(-50%)",
		cursor: "pointer",
		zIndex: 10,
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 20,
		color: "#fff",
		fontFamily: "Branding SemiBold",
		fontSize: 14,
	},
	bannerModerator: {
		backgroundColor: "#e6c765",
		width: "100%",
		color: "#0f1d2e",
		fontFamily: "branding Bold",
		padding: "15px 0",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	messages: {
		messageContainer: {
			display: "flex",
			alignItems: "center",
			gap: 20,
			marginLeft: 10,
			marginRight: 10,
			padding: "0 5px",
			justifyContent: "space-between",
			borderRadius: 10,
			transition: "background-color 0.3s ease",
		},
		name: {
			fontFamily: "Branding Bold",
			color: "rgba(22,42,65,0.93)",
			marginRight: 0,
			fontSize: 16,
		},
		messageContent: {
			fontFamily: "Branding SemiBold",
			color: "rgba(24,43,67,0.72)",
			fontSize: 15,
			wordBreak: "break-all",
		},
		trashContainer: {
			cursor: "pointer",
			backgroundColor: "#162a41",
			borderRadius: 10,
			margin: "2px 0",
			height: 30,
			width: 30,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		messageDeleted: {
			fontFamily: "Branding SemiBold",
			color: "rgba(0,0,0,0.5)",
			fontSize: 15,
		},
		welcomeContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			margin: "10px 0",
		},
		welcome: {
			fontFamily: "Branding Bold",
			color: "rgba(24,43,67,0.72)",
		},
		time: {
			fontFamily: "Branding SemiBold",
			color: "rgba(24,43,67,0.50)",
		},
		rightContainer: {
			transition: "opacity 0.3s ease",
			display: "flex",
			alignItems: "center",
			gap: 10,
		},
	},
};
